import React from "react"
import { graphql } from "gatsby"

import Moment from "react-moment"

import Seo from "../components/seo"
import Layout from "../components/layout"
import NewsletterForm from "../components/newsletterForm"

export const query = graphql`
  query SentmailQuery($id: String!) {
    strapiSentmails(strapiId: { eq: $id }) {
      strapiId
      content
      publishedAt
      title
    }
  }
`

const Sentmail = ({ data }) => {
  const sentmail = data.strapiSentmails

  return (
    <Layout>
      <Seo title={sentmail.title} />

      <section className="hero is-dark is-small">
        <div className="hero-body">
          <div className="container pb-6 ">
            <div className="columns is-centered mt-6">
              <div className="column is-7 has-text-centered">
                <h1 className="is-size-3 has-text-weight-bold has-text-white title-font">
                  {sentmail.title}
                </h1>
                <p className="has-text-white has-opacity-65">
                  Added on{" "}
                  <Moment format="MMM Do YYYY">{sentmail.publishedAt}</Moment>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-8">
              <div className="content">
                <figure className="image"></figure>
                <div
                  dangerouslySetInnerHTML={{ __html: sentmail.content }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-12">
              <NewsletterForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Sentmail
