import React, { useState, useRef } from "react"
import axios from "axios"

const NewsletterForm = () => {
  const [error, setError] = useState()
  const inputEl = useRef(null)

  const createSubscriber = () => {
    if (
      /^[0-9?A-z0-9?]+(\.)?[0-9?A-z0-9?]+@[A-z]+\.[A-z]{1,3}.?[A-z]{0,3}$/.test(
        inputEl.current.value
      )
    ) {
      axios
        .post(
          "https://api.mailblast.io/v1/lists/31053643-35c1-45cf-8677-acdf25affdad/subscribers",
          {
            data: {
              attributes: {
                email: inputEl.current.value,
                first_name: "",
                last_name: "",
              },
            },
          },
          {
            headers: {
              "X-USER-TOKEN": "7xxFVsdHC5cEyysseWpbeNsMyy-z2A",
              "X-USER-EMAIL": "radu@winspire.co",
            },
          }
        )
        .then(function (res) {
          console.log(res)
          window.location.replace("/newsletter-confirmation")
        })
        .catch(function (error) {
          setError("This email is already subscribed!")
          console.log(error)
        })
    } else {
      setError("Please enter a valid email address")
    }
  }

  return (
    <div className="has-background-dark px-6 py-6">
      <div className="columns is-vcentered">
        <div className="column is-6">
          <div>
            <p className="has-text-weight-bold is-size-3 has-text-white">
              <svg
                width="32"
                height="32"
                className="mr-2 top-2px"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.3333 4H4.66667C3.194 4 2 5.194 2 6.66667V18C2 19.4727 3.194 20.6667 4.66667 20.6667H23.3333C24.806 20.6667 26 19.4727 26 18V6.66667C26 5.194 24.806 4 23.3333 4Z"
                  fill="#E96463"
                />
                <path
                  d="M2.72532 4.84863C2.41799 5.17663 2.19532 5.58063 2.08466 6.0313L14 14.6666L25.9153 6.0313C25.8047 5.58063 25.582 5.17663 25.2747 4.8493L14 13.02L2.72532 4.84863Z"
                  fill="#B84444"
                />
                <path
                  d="M27.3333 11.3333H8.66667C7.194 11.3333 6 12.5273 6 14V25.3333C6 26.806 7.194 28 8.66667 28H27.3333C28.806 28 30 26.806 30 25.3333V14C30 12.5273 28.806 11.3333 27.3333 11.3333Z"
                  fill="#45B6F2"
                />
                <path
                  d="M6.72532 12.182C6.41799 12.5093 6.19532 12.914 6.08466 13.364L18 22L29.9153 13.3647C29.8047 12.914 29.582 12.51 29.2747 12.1827L18 20.3533L6.72532 12.182Z"
                  fill="#2477A3"
                />
              </svg>
              Weekly Newsletter
            </p>
            <p className="has-text-white">
              Get the best resources of the week straight in to your inbox
            </p>
          </div>
        </div>

        <div className="column is-6">
          {/* <form
            method="post"
            action="https://www.mailblast.io/lists/31053643-35c1-45cf-8677-acdf25affdad/subscribers/create_external"
            className="mb-form"
            noValidate="novalidate"
          > */}
          <div className="field has-addons is-marginless">
            <div className="control" style={{ width: "100%" }}>
              <input
                type="email"
                name="subscriber[email]"
                className="input is-fullwidth"
                required=""
                aria-required="true"
                placeholder="Your email address"
                ref={inputEl}
              />
            </div>
            <div className="control">
              {/* <button type="submit" className="button is-primary">
                  Subscribe
                </button> */}
              <button
                onClick={() => createSubscriber()}
                className="button is-primary"
              >
                Subscribe
              </button>
            </div>
          </div>
          {error ? (
            <p className="has-text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                fill="#e96463"
                className="is-24 top-6px mr-1"
              >
                <path
                  d="M25,2C12.319,2,2,12.319,2,25s10.319,23,23,23s23-10.319,23-23S37.681,2,25,2z M33.71,32.29c0.39,0.39,0.39,1.03,0,1.42 C33.51,33.9,33.26,34,33,34s-0.51-0.1-0.71-0.29L25,26.42l-7.29,7.29C17.51,33.9,17.26,34,17,34s-0.51-0.1-0.71-0.29 c-0.39-0.39-0.39-1.03,0-1.42L23.58,25l-7.29-7.29c-0.39-0.39-0.39-1.03,0-1.42c0.39-0.39,1.03-0.39,1.42,0L25,23.58l7.29-7.29 c0.39-0.39,1.03-0.39,1.42,0c0.39,0.39,0.39,1.03,0,1.42L26.42,25L33.71,32.29z"
                  fill="#e96463"
                />
              </svg>
              {error}
            </p>
          ) : (
            ""
          )}

          <div className="mb-form-group-tk is-hidden" aria-hidden="true">
            <input
              type="text"
              name="mb_3105364335c145cf8677acdf25affdad"
              tabIndex="-1"
              value=""
            />

            <input
              type="hidden"
              name="success_redirect"
              className="mb-subscribe-redirect-url"
              value="https://growkarma-resources.netlify.app/newsletter-confirmation/"
            />
          </div>
          {/* </form> */}
          {/* <script src="https://cdn.mailblast.io/javascripts/mb-embed-1-2.js"></script> */}
        </div>
      </div>
    </div>
  )
}

export default NewsletterForm
